import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f4fd0f6af14d4e341a659ea3686801b7@o4506390701604864.ingest.sentry.io/4506482821627904",
  debug: false,
  environment: "staging",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()